import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { url2section } from 'ion-sections'
import loadable from '@loadable/component'
import NewspaperButton from '../../../assets/images/buttons/sundaytribune-button.png'
import { DefaultSectionPageSkeletonWrapper, ArticlePageSkeleton } from '../components/Skeletons'

const TitleSectionPage = loadable(() => import('../pages/TitleSectionPage'), {
  fallback: <DefaultSectionPageSkeletonWrapper />
})
const TagPage  = loadable(() => import('../pages/TagPage'))
const Article = loadable(() => import('../pages/Article'), {
  fallback: <ArticlePageSkeleton />
})

const RenderArticle = (props) => {
  const { contentKey } = useParams()
  return <Article {...props} contentKey={contentKey} />
}

const SundayTribune = (props) => (
  <Switch>
    <Route path='/sunday-tribune' exact render={() => (
      <TitleSectionPage {...props}
        section='sunday-tribune'
        sectionLabel='Sunday Tribune'
        relatedSections={[{ id: 'sunday-tribune/news' }, { id: 'sunday-tribune/sport' }, { id: 'sunday-tribune/world' }, { id: 'sunday-tribune/travel' }, { id: 'sunday-tribune/lifestyle' }, { id: 'sunday-tribune/entertainment' }, { id: 'sunday-tribune/opinion' }]}
        title='Sunday Tribune'
        description='-'
        publication='Sunday Tribune'
        newspaperTitle='The Sunday Tribune on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://sundaytribune.zinioapps.com/shop'
      />
    )} />
    <Route path='/sunday-tribune/news' exact render={() => (
      <TitleSectionPage {...props}
        section='sunday-tribune/news'
        relatedSections={[{ id: 'sunday-tribune', label: 'Sunday Tribune' }, { id: 'sunday-tribune/sport' }, { id: 'sunday-tribune/world' }, { id: 'sunday-tribune/travel' }, { id: 'sunday-tribune/lifestyle' }, { id: 'sunday-tribune/entertainment' }, { id: 'sunday-tribune/opinion' }]}
        title='Sunday Tribune'
        description='-'
        publication='Sunday Tribune'
        newspaperTitle='The Sunday Tribune on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://sundaytribune.zinioapps.com/shop'
      />
    )} />
    <Route path='/sunday-tribune/sport' exact render={() => (
      <TitleSectionPage {...props}
        section='sunday-tribune/sport'
        relatedSections={[{ id: 'sunday-tribune', label: 'Sunday Tribune' }, { id: 'sunday-tribune/news' }, { id: 'sunday-tribune/world' }, { id: 'sunday-tribune/travel' }, { id: 'sunday-tribune/lifestyle' }, { id: 'sunday-tribune/entertainment' }, { id: 'sunday-tribune/opinion' }]}
        title='Sunday Tribune'
        description='-'
        publication='Sunday Tribune'
        newspaperTitle='The Sunday Tribune on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://sundaytribune.zinioapps.com/shop'
      />
    )} />
    <Route path='/sunday-tribune/world' exact render={() => (
      <TitleSectionPage {...props}
        section='sunday-tribune/world'
        relatedSections={[{ id: 'sunday-tribune', label: 'Sunday Tribune' }, { id: 'sunday-tribune/news' }, { id: 'sunday-tribune/sport' }, { id: 'sunday-tribune/travel' }, { id: 'sunday-tribune/lifestyle' }, { id: 'sunday-tribune/entertainment' }, { id: 'sunday-tribune/opinion' }]}
        title='Sunday Tribune'
        description='-'
        publication='Sunday Tribune'
        newspaperTitle='The Sunday Tribune on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://sundaytribune.zinioapps.com/shop'
      />
    )} />
    <Route path='/sunday-tribune/travel' exact render={() => (
      <TitleSectionPage {...props}
        section='sunday-tribune/travel'
        relatedSections={[{ id: 'sunday-tribune', label: 'Sunday Tribune' }, { id: 'sunday-tribune/news' }, { id: 'sunday-tribune/sport' }, { id: 'sunday-tribune/world' }, { id: 'sunday-tribune/lifestyle' }, { id: 'sunday-tribune/entertainment' }, { id: 'sunday-tribune/opinion' }]}
        title='Sunday Tribune'
        description='-'
        publication='Sunday Tribune'
        newspaperTitle='The Sunday Tribune on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://sundaytribune.zinioapps.com/shop'
      />
    )} />
    <Route path='/sunday-tribune/lifestyle' exact render={() => (
      <TitleSectionPage {...props}
        section='sunday-tribune/lifestyle'
        relatedSections={[{ id: 'sunday-tribune', label: 'Sunday Tribune' }, { id: 'sunday-tribune/news' }, { id: 'sunday-tribune/sport' }, { id: 'sunday-tribune/world' }, { id: 'sunday-tribune/travel' }, { id: 'sunday-tribune/entertainment' }, { id: 'sunday-tribune/opinion' }]}
        title='Sunday Tribune'
        description='-'
        publication='Sunday Tribune'
        newspaperTitle='The Sunday Tribune on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://sundaytribune.zinioapps.com/shop'
      />
    )} />
    <Route path='/sunday-tribune/entertainment' exact render={() => (
      <TitleSectionPage {...props}
        section='sunday-tribune/entertainment'
        relatedSections={[{ id: 'sunday-tribune', label: 'Sunday Tribune' }, { id: 'sunday-tribune/news' }, { id: 'sunday-tribune/sport' }, { id: 'sunday-tribune/world' }, { id: 'sunday-tribune/travel' }, { id: 'sunday-tribune/lifestyle' }, { id: 'sunday-tribune/opinion' }]}
        title='Sunday Tribune'
        description='-'
        publication='Sunday Tribune'
        newspaperTitle='The Sunday Tribune on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://sundaytribune.zinioapps.com/shop'
      />
    )} />
    <Route path='/sunday-tribune/opinion' exact render={() => (
      <TitleSectionPage {...props}
        section='sunday-tribune/opinion'
        relatedSections={[{ id: 'sunday-tribune', label: 'Sunday Tribune' }, { id: 'sunday-tribune/news' }, { id: 'sunday-tribune/sport' }, { id: 'sunday-tribune/world' }, { id: 'sunday-tribune/travel' }, { id: 'sunday-tribune/lifestyle' }, { id: 'sunday-tribune/entertainment' }]}
        title='Sunday Tribune'
        description='-'
        publication='Sunday Tribune'
        newspaperTitle='The Sunday Tribune on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://sundaytribune.zinioapps.com/shop'
      />
    )} />
    <Route path={'/sunday-tribune/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/sunday-tribune/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/sunday-tribune/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9]{5,})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/sunday-tribune/:title*([-])*:contentKey([0-9]{5,})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/sunday-tribune/:section([a-zA-Z0-9-/]+)'} exact render={() => (
      <TagPage {...props}
        section={url2section(props.location.pathname)}
        url={props.location.pathname}
      />
    )} />
  </Switch>
)

export default SundayTribune
